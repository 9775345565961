import {
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken
} from '@/utils/auth'
import { Message } from 'element-ui'
import { setStore, getStore } from '@/utils/store'
import { validatenull } from '@/utils/validate'
import {
  loginByUsername,
  loginBySSO,
  bindByUsername,
  getUserInfo,
  logout,
  refreshToken
} from '@/api/user'
import md5 from 'js-md5'
import Cookies from 'js-cookie'

const user = {
  state: {
    tenantId: getStore({ name: 'tenantId' }) || '',
    userInfo: getStore({ name: 'userInfo' }) || [],
    token: getStore({ name: 'token' }) || '',
    refreshToken: getStore({ name: 'refreshToken' }) || ''
  },
  actions: {
    //根据用户名登录
    LoginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByUsername(
          userInfo.tenantId,
          userInfo.deptId,
          userInfo.roleId,
          userInfo.username,
          md5(userInfo.password),
          userInfo.type,
          userInfo.key,
          userInfo.code
        )
          .then(res => {
            const data = (res.data || {}).data || {}
            if (data.error_description) {
              Message({
                message: data.error_description,
                type: 'error'
              })
            } else {
              commit('SET_TOKEN', data.access_token)
              commit('SET_REFRESH_TOKEN', data.refresh_token)
              commit('SET_TENANT_ID', data.tenant_id)
              commit('SET_USER_INFO', data)
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 根据 sso cookie 登录
    LoginBySSOCookie({ commit }, ssoInfo) {
      return new Promise((resolve, reject) => {
        loginBySSO(ssoInfo.ssoTenantId, ssoInfo.cookieName, ssoInfo.cookieValue)
          .then(res => {
            const data = res.data
            if (data.error_description) {
              Message({
                message: data.error_description,
                type: 'error'
              })
            } else {
              const userInfo = data.data || {}
              commit('SET_TOKEN', userInfo.access_token)
              commit('SET_REFRESH_TOKEN', userInfo.refresh_token)
              commit('SET_TENANT_ID', userInfo.tenant_id)
              commit('SET_USER_INFO', userInfo)
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 绑定账户
    BindUserInfo({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const { cookieName, cookieValue } = userInfo
        Cookies.set(cookieName, cookieValue)
        bindByUsername(
          userInfo.tenantId,
          userInfo.deptId,
          userInfo.roleId,
          userInfo.username,
          md5(userInfo.password),
          userInfo.type,
          userInfo.key,
          userInfo.code
        )
          .then(res => {
            const data = res.data
            if (data.error_description) {
              Message({
                message: data.error_description,
                type: 'error'
              })
            } else {
              const userInfo = data.data || {}
              commit('SET_TOKEN', userInfo.access_token)
              commit('SET_REFRESH_TOKEN', userInfo.refresh_token)
              commit('SET_TENANT_ID', userInfo.tenant_id)
              commit('SET_USER_INFO', userInfo)
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            Cookies.remove(cookieName)
          })
      })
    },
    //获取用户信息
    GetUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(res => {
            const data = res.data.data
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    //刷新token
    refreshToken({ state, commit }, userInfo) {
      window.console.log('handle refresh token')
      return new Promise((resolve, reject) => {
        refreshToken(
          state.refreshToken,
          state.tenantId,
          !validatenull(userInfo) ? userInfo.deptId : state.userInfo.dept_id,
          !validatenull(userInfo) ? userInfo.roleId : state.userInfo.role_id
        )
          .then(res => {
            const data = (res.data || {}).data || {}
            commit('SET_TOKEN', data.access_token)
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('SET_USER_INFO', data)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '')
            removeToken()
            removeRefreshToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    //注销session
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        removeRefreshToken()
        resolve()
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token)
      state.token = token
      setStore({ name: 'token', content: state.token })
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      setRefreshToken(refreshToken)
      state.refreshToken = refreshToken
      setStore({ name: 'refreshToken', content: state.refreshToken })
    },
    SET_TENANT_ID: (state, tenantId) => {
      state.tenantId = tenantId
      setStore({ name: 'tenantId', content: state.tenantId })
    },
    SET_USER_INFO: (state, userInfo) => {
      if (validatenull(userInfo.avatar)) {
        userInfo.avatar = '/img/bg/img-logo.png'
      }
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
    }
  }
}
export default user
