import Vue from 'vue'
import ElementUI from 'element-ui'
import { website } from '@/config.js'
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css'
import dataV from '@jiaminghi/data-view'
import router from './router.js'
import App from './App.vue'
import './styles/common.scss'
import '@/utils/es6'
import '@/mock/'
import { Base64 } from 'js-base64'
//导入主题文件
import '@/theme/index.js'
import store from '@/store'
import request from '@/axios'

/** 常用全局方法 **/
//对话框操作
window.updateDialog = function ({ refs = {}, viewIds = [], isShow = false }) {
  viewIds.forEach(viewId => {
    const view = refs[viewId]
    if (view) {
      view.$el.style.display = isShow ? 'block' : 'none'
      view.$parent.$el.style.display = isShow ? 'block' : 'none'
    }
  })
}
// base64
window.base64 = Base64
// request
window.$request = request

Vue.config.productionTip = false
window.axios = axios
document.title = website.title
Vue.use(ElementUI)
Vue.use(window.AVUE)
Vue.prototype.$website = website
Vue.use(dataV)
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
