import Cookies from 'js-cookie'
import { getQueryString } from '@/utils/utils'
import { validatenull } from '@/echart/util'

const TokenKey = 'saber-access-token'
const RefreshTokenKey = 'saber-refresh-token'

function clearUrlParam() {
  const href = window.location.href.split('?')
  if (href.length > 1) history.pushState({}, 0, href[0])
}

export function getToken() {
  const token = getQueryString('token')
  return !validatenull(token) ? token : Cookies.get(TokenKey)
}

export function setToken(token) {
  clearUrlParam()
  return Cookies.set(TokenKey, token)
}

export function getRefreshToken() {
  const refresh = getQueryString('refresh')
  return !validatenull(refresh) ? refresh : Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
  clearUrlParam()
  return Cookies.set(RefreshTokenKey, token)
}

export function removeToken() {
  clearUrlParam()
  return Cookies.remove(TokenKey)
}

export function removeRefreshToken() {
  clearUrlParam()
  return Cookies.remove(RefreshTokenKey)
}
