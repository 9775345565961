// import { Loading } from 'element-ui';
import { validatenull } from '@/echart/util'
import { checkUrl, getFunction } from '@/utils/utils'
import { getToken } from '@/utils/auth'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { Base64 } from 'js-base64'
import { website } from '@/config.js'

window.$glob = {
  url: '',
  params: {},
  query: {},
  headers: {}
}
function getGlobParams() {
  var query = window.location.search.substring(1)
  query = query.split('&')
  query.forEach(ele => {
    var pair = ele.split('=')
    window.$glob.params[pair[0]] = pair[1]
  })
}

axios.defaults.timeout = 100000
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true
axios.interceptors.request.use(
  config => {
    // loadingInstance = Loading.service({
    //   text: '拼命加载中',
    //   background: 'rgba(0,0,0,0)',
    //   spinner: 'el-icon-loading'
    // });
    getGlobParams()
    if (!checkUrl(config.url)) {
      config.url = window.$glob.url + config.url
    }

    if (!validatenull(window.$glob.header)) {
      let header = getFunction(window.$glob.header)()
      config.headers = Object.assign(config.headers, header)
    }

    // 设置 token
    config.headers['Authorization'] = `Basic ${Base64.encode(
      `${website.clientId}:${website.clientSecret}`
    )}`
    if (getToken()) {
      config.headers[website.tokenHeader] = 'bearer ' + getToken()
    }

    //获取全局参数
    if (typeof config.data === 'object' && !validatenull(window.$glob.query)) {
      let query = getFunction(window.$glob.query)()
      let data = Object.assign(window.$glob.params, query)
      if (config.method == 'get') {
        config.params = Object.assign(config.params, data)
      } else if (config.method == 'post') {
        config.data = Object.assign(config.data, data)
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//HTTPrequest拦截
axios.interceptors.response.use(
  res => {
    // loadingInstance.close();

    //获取状态码
    const status = res.data.code || res.status

    if (status === 401) {
      //如果是401则跳转到登录页面
      store.dispatch('FedLogOut').then(() => {
        router.push({ path: '/login' })
      })
    }

    return res
  },
  error => {
    // loadingInstance.close();
    return Promise.reject(new Error(error))
  }
)

export default axios
