import Router from 'vue-router'
import Vue from 'vue'
Vue.use(Router)
const vueRouter = new Router({
  base: process.env.BASE_URL,
  mode: process.env.VUE_APP_MODE,
  routes: [
    {
      path: '/login',
      name: '登录页',
      component: () =>
        import(/* webpackChunkName: "page" */ '@/page/login/index'),
      meta: {
        keepAlive: true,
        isTab: false,
        isAuth: false
      }
    },
    {
      path: '/binding',
      name: '账户绑定',
      component: () =>
        import(/* webpackChunkName: "page" */ '@/page/login/binding'),
      meta: {
        keepAlive: true,
        isTab: false,
        isAuth: false
      }
    },
    {
      path: '/',
      name: 'list',
      component: () => import(/* webpackChunkName: "page" */ '@/page/index')
    },
    {
      path: '/create',
      name: 'create',
      component: () => import(/* webpackChunkName: "page" */ '@/page/create')
    },
    {
      path: '/build/:id',
      name: 'build',
      component: () => import(/* webpackChunkName: "page" */ '@/page/build')
    },
    {
      path: '/view/:id',
      name: 'view',
      component: () => import(/* webpackChunkName: "page" */ '@/page/view')
    }
  ]
})
export default vueRouter
