import request from '@/axios'

export const loginByUsername = (
  tenantId,
  deptId,
  roleId,
  username,
  password,
  type,
  key,
  code
) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': deptId,
      'Role-Id': roleId,
      'Captcha-Key': key,
      'Captcha-Code': code
    },
    params: {
      tenantId,
      username,
      password,
      grant_type: 'captcha',
      scope: 'all',
      type
    }
  })

export const loginBySSO = (ssoTenantId, cookieName, cookieValue) => {
  return request({
    url: '/api/blade-sso-app/oauth/sso/ssoLogin',
    method: 'post',
    headers: { 'Sso-Tenant-Id': ssoTenantId },
    params: { ssoTenantId, cookieName, cookieValue }
  })
}

export const refreshToken = (refresh_token, tenantId, deptId, roleId) =>
  request({
    url: '/api/blade-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': deptId,
      'Role-Id': roleId
    },
    params: {
      tenantId,
      refresh_token,
      grant_type: 'refresh_token',
      scope: 'all'
    }
  })

export const bindByUsername = (
  tenantId,
  deptId,
  roleId,
  username,
  password,
  type,
  key,
  code
) => {
  return request({
    url: '/api/blade-sso-app/oauth/sso/bindUser',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': deptId,
      'Role-Id': roleId,
      'Captcha-Key': key,
      'Captcha-Code': code
    },
    params: {
      tenantId,
      username,
      password,
      grant_type: 'captcha',
      scope: 'all',
      type
    }
  })
}

export const getCaptcha = () =>
  request({
    url: '/api/blade-auth/oauth/captcha',
    method: 'get'
  })

export const logout = () =>
  request({
    url: '/api/blade-auth/oauth/logout',
    method: 'get'
  })

export const getUserInfo = () =>
  request({
    url: '/api/blade-auth/oauth/user-info',
    method: 'get'
  })
